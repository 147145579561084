<template>
	<Table
		:pgLimitGet="pgLimit"
		backendDirectory="chamado/listar"
		:busca="{
            busca: busca.busca || null,
            idstatus: busca.idstatus || null,
            idcategoria: busca.idcategoria || null,
            idsubcategoria: busca.idsubcategoria || null,
            idusuariocadastro: usuario.idusuario,
            dtini: `${busca.dtinicio} 00:00:00`,
            dtfim: `${busca.dtfim} 23:59:59`,
        }"
		:filter="true"
		:keydown="keydown"
		:headers="{
            idchamado: {nome: 'ID', tipo: 'link', link: '/chamado/get/'},
            chamado: {nome: 'Chamado', tipo: 'texto'},
            descricao: {nome: 'Descrição', tipo: 'texto'},
			solucao: {nome: 'Solucao', tipo: 'texto'},
            dataabertura: {nome: 'Data de abertura', tipo: 'texto'},
            dataconclusao: {nome: 'Data de retorno', tipo: 'texto'},
            status: {nome: 'Status', tipo: 'condicionais', class: 'text-center', condicionais: [
                {condicao: 'Aberto', cor: 'yellow darken-4'},
                {condicao: 'Em andamento', cor: 'amber darken-4'},
                {condicao: 'Aguardando terceiros', cor: 'orange darken-4'},
                {condicao: 'Concluído', cor: 'green darken-4'},
            ]},
        }"
	>
		<v-col class="d-none d-md-block pr-1">
			<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtinicio" label="Data inicial" />
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtfim" label="Data final" />
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="categorias.lista"
				item-text="categoria"
				item-value="idcategoria"
				label="Categorias"
				outlined
				dense
				v-model="busca.idcategoria"
				@change="listarSubCategorias()"
			></v-select>
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="subCategorias.lista"
				item-text="subcategoria"
				item-value="idsubcategoria"
				label="Sub categoria"
				outlined
				dense
				v-model="busca.idsubcategoria"
			></v-select>
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="status.lista"
				item-text="status"
				item-value="idstatus"
				label="Status"
				outlined
				dense
				v-model="busca.idstatus"
			></v-select>
		</v-col>
		<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
			<v-sheet class="text-center" height="100%">
				<v-row class="pa-3">
					<v-col cols="6" class="pr-3 pr-md-0 mb-n8">
						<v-select
							clearable
							:items="categorias.lista"
							item-text="categoria"
							item-value="idcategoria"
							label="Categoria"
							outlined
							dense
							v-model="busca.idcategoria"
							@change="listarSubCategorias()"
						></v-select>
					</v-col>
					<v-col cols="6" class="pr-3 pr-md-0 mb-n8">
						<v-select
							clearable
							:items="subCategorias.lista"
							item-text="subcategoria"
							item-value="idsubcategoria"
							label="Sub categoria"
							outlined
							dense
							v-model="busca.idsubcategoria"
						></v-select>
					</v-col>
					<v-col cols="12" class="pr-3 pr-md-0 mb-n8">
						<v-select
							clearable
							:items="status.lista"
							item-text="status"
							item-value="idstatus"
							label="Status"
							outlined
							dense
							v-model="busca.idstatus"
						></v-select>
					</v-col>
					<v-col cols="6">
						<InputDatePicker
							v-model="busca.dtinicio"
							label="Data inicial"
							:outlined="true"
							:dense="true"
						/>
					</v-col>
					<v-col cols="6">
						<InputDatePicker v-model="busca.dtfim" label="Data final" :outlined="true" :dense="true" />
					</v-col>
					<v-col cols="6">
						<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn text class="mt-n3" color="primary" @click="keydown = !keydown, sheet = !sheet">Filtrar</v-btn>
					</v-col>
				</v-row>
			</v-sheet>
		</v-bottom-sheet>
		<v-col class="pl-1">
			<v-text-field
				class="d-flex d-md-none"
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				append-outer-icon="mdi-menu"
				@click:append-outer="sheet = !sheet"
				@keydown.enter="keydown = !keydown"
			/>
			<v-text-field
				class="d-none d-md-block"
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				@keydown.enter="keydown = !keydown"
			/>
		</v-col>
	</Table>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Table from "../Widgets/Table";
import InputDatePicker from "../Widgets/InputDatePicker";

export default {
	name: "ChamadoLista",
	components: { Table, InputDatePicker },
	data: () => ({
		sheet: false,
		keydown: false,
		status: { lista: [] },
		categorias: { lista: [] },
		subCategorias: { lista: [] },
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
			idcategoria: null,
			idsubcategoria: null,
			idstatus: null,
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
	},
	methods: {
		listarStatus() {
			this.status.lista = [];
			return axios
				.post(`${this.backendUrl}chamado/status`)
				.then((res) => {
					if (res.data.lista != null) {
						this.status = res.data;
					}
				});
		},
		listarCategorias() {
			this.categorias.lista = [];
			return axios
				.post(`${this.backendUrl}chamado/categoria`, {
					iddepartamento: this.busca.iddepartamento,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.categorias = res.data;
					}
				});
		},
		listarSubCategorias() {
			this.subCategorias.lista = [];
			return axios
				.post(`${this.backendUrl}chamado/subcategoria`, {
					idcategoria: this.busca.idcategoria,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.subCategorias = res.data;
					}
				});
		},
		async init() {
			await this.listarStatus();
			await this.listarCategorias();
		},
	},
	watch: {
		"busca.dtfim": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
		},
		"busca.dtinicio": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>